import {
    submitAuthorization,
    submitRegistration,
    authorizationThroughMos,
    fetchUserData,
    fastAuthorization,
    sendCode,
    saveUserData,
    deleteInstance,
    fetchUserLocation,
    slotEventSubscribe,
    slotEventUnsubscribe,
} from "./serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";
import {appConfig} from "@/lib/appConfig";
import {cleanBadMessage} from "@/lib/cleanBadMessage";
import router from "@/router";
import {getShortName} from "@/lib/textParser";

const DISCONNECT_TIMER = 60;
const ONE_SECOND = 1000;

// store with all authenticated user data
export const user = {
    state: {
        // authorization user token for api
        token: null,
        userData: null,
        webSocket: null,
        unrealToken: 'alshf98hoeuhgi20gh9oweijfgodigjp498hg0p43ghiwrghp098ierghre9ihgj',
        userLocation: null,
        isInstanceClear: false,
        disconnectTimer: DISCONNECT_TIMER,
        disconnectInterval: null,
        showDisconnectModal: false,

        eventMembers: []

    },
    mutations: {
        // setting token state
        setToken(state, data) {
            state.token = data;
        },
        setUserData(state, data) {
            state.userData = data;
            // if (state.userData) {
            //     state.userData.name = getShortName(state.userData?.name);
            // }

        },
        setLocation(state, data) {
            state.userLocation = data;
        },
        disconnectModal(state, value) {
            state.showDisconnectModal = value;
        },

        setUserLocation(state) {
            if (!state.userLocation) {
                return;
            }

            let sideData = JSON.parse(state.userData.sideData);
            if (!sideData) {
                sideData = {};
            }
            sideData['userLocation'] = [state.userLocation.country, state.userLocation.region, state.userLocation.city].join(', ');
            state.userData.sideData = JSON.stringify(sideData);
        },
        createWS(state, callback) {
            if (state.webSocket?.readyState === 1) {
                return;
            }

            if (sessionStorage.getItem('slotId')) {
                this.commit('setChatParams', {
                    receiverId: sessionStorage.getItem('slotId'),
                    chatType: 'event',
                    receiverName: null
                });
            }

            state.webSocket = new WebSocket(appConfig.socket);
            state.webSocket.onopen = () => {
                if (state.webSocket.readyState === 1) {
                    this.dispatch('initializeSocket');
                    this.dispatch('fetchDialogues');
                    this.dispatch('fetchVote');
                    if (callback) {
                        callback();
                    }
                }
                // document.addEventListener('ShowEventChat', (event) => {
                //     if (event.detail) {
                //         this.commit('setChatParams', {receiverId: 'event', chatType: 'event', receiverName: 'event'});
                //         this.dispatch('fetchMessages');
                //     } else {
                //         this.commit('clearChat');
                //     }
                // })
            }
            state.webSocket.onmessage = ({data}) => {
                try {
                    data = JSON.parse(data);
                    switch (data.action) {
                        case 'WRITE_NEW_MESSAGE':
                            this.commit('addNewDialogue', cleanBadMessage(data.msg))
                            this.commit('addNewMessageInList', cleanBadMessage(data.msg))
                            break;
                        case 'MESSAGES':
                            this.commit('addMessagesInList', data.messages.map(cleanBadMessage));
                            break;
                        case 'DIALOGUES':
                            this.commit('addDialogues', data.messages);
                            break;
                        case 'EXCURSION_OPEN_SLIDE':
                            if (sessionStorage.getItem('slotId') && data?.eventId === sessionStorage.getItem('slotId')) {
                                this.commit('setCurrentViewSlide', data);
                            }
                            break;
                        case 'CALL_DROPPED':
                            this.commit('dropCall', data?.callFromUser)
                            break;
                        case 'INVITE_CHAT_ANSWER':
                            document.dispatchEvent(new CustomEvent('InviteChat', {detail: data}))
                            break;
                        case 'EVENT_JOINED':
                            if (data.slotId !== sessionStorage.getItem('slotId')) return;

                            // eslint-disable-next-line no-case-declarations
                            const memberIndex = state.eventMembers?.findIndex(item => item?.id === data?.userData?.id);

                            if (data?.isExit) {
                                if (memberIndex !== -1) {
                                    state.eventMembers?.splice(memberIndex, 1);
                                }

                                if (sessionStorage.getItem('isLobby') === 'true') {
                                    window?.dispatchEvent(new CustomEvent('GuideLoaded', {detail: false}));
                                } else {
                                    this.dispatch('disconnect');
                                }
                                return;
                            }


                            if (memberIndex === -1) {
                                if (data?.userData?.name) {
                                    data.userData.name = getShortName(data.userData.name)
                                }
                                state.eventMembers?.push(data?.userData);
                            }


                            if (data?.peerId) {
                                this.commit('callUp', {userName: null, userId: data?.peerId});
                                if (sessionStorage.getItem('eventType') === 'lecture') {
                                    document.dispatchEvent(new Event('LoadSection'));

                                }
                            } else {

                                if (data?.instance) {
                                    router.push({name: 'mainPage'}).then(() => {
                                        localStorage.setItem('notification', 'eventNotStarted');
                                    });
                                    return;
                                }
                            }


                            if (data?.instance) {
                                if (sessionStorage.getItem('isLobby') === 'true') {
                                    return window?.dispatchEvent(new CustomEvent('GuideLoaded', {detail: true}));
                                }

                                localStorage.setItem('game_socket', data?.instance);


                                if (sessionStorage.getItem('eventType') === 'excursion' && state.userData?.role !== 'guide') {
                                    document.dispatchEvent(new CustomEvent('LockedKeyboard', {detail: true}));
                                    document.dispatchEvent(new CustomEvent("launchGame", {detail: false}));
                                    document.dispatchEvent(new Event('gameLoaded'));
                                    this.commit("setLoader", false);

                                }

                            }


                            if (localStorage.getItem('game_socket') || sessionStorage.getItem('isLobby') === 'true') return;
                            this.dispatch('fetchInstance', 'main').then((socket) => {
                                localStorage.setItem('game_socket', socket);
                                document.dispatchEvent(new CustomEvent("launchGame", {detail: false}));
                                this.commit("setLoader", false);
                            });


                            break;
                        case 'VOTE':
                            this.commit('setVote', data.message);
                            break;

                        case 'EVENT_CLIENTS':
                            if (data?.usersData) {
                                data.usersData = data?.usersData?.map((item) => {
                                    if (item?.name) {
                                        item.name = getShortName(item.name);
                                    }
                                    return item
                                })
                            }

                            this.commit('setEventMembers', data?.usersData)
                            break;
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            state.webSocket.onclose = (event) => {
                if (!event.wasClean) {
                    state.webSocket = null;
                    this.commit('createWS');
                    return;
                }
                console.log('ws close');
            }
        },
        closeWS(state) {

            state.webSocket?.close();
            state.webSocket = null;
            state.eventMembers.length = 0;
        },
        setClearInstanceStatus(state, data) {
            state.isInstanceClear = data;
        },

        setEventMembers(state, data) {
            state.eventMembers = [...data];
        },
        changeUserRole(state, data) {
            state.userData.role = data;
        }

    },
    actions: {
        async disconnect({state, commit, dispatch}, reason = 'eventEnd') {
            localStorage.setItem('notification', reason);
            commit('disconnectModal', true);
            const startTime = new Date();
            const endDate = new Date(startTime.getTime() + DISCONNECT_TIMER * ONE_SECOND);

            state.disconnect = setInterval(() => {
                state.disconnectTimer -= 1;
                if (endDate <= new Date() || state.disconnectTimer < 0) {
                    dispatch('disconnectRedirect')
                        .then(() => Promise.resolve(1))
                }

            }, ONE_SECOND);
        },

        async disconnectRedirect({state, commit}) {
            await router.push({name: 'mainPage'})
                .then(() => {
                    state.disconnectTimer = DISCONNECT_TIMER;
                    commit('disconnectModal', false);
                    if (state.disconnectInterval) {
                        clearInterval(state.disconnectInterval);
                    }
                });
        },

        async eventEnd({getters}, eventId) {
            getters.webSocket?.send(JSON.stringify({
                action: 'EVENT_JOIN',
                token: getters.userToken,
                eventId,
                slotId: sessionStorage.getItem('slotId'),
                peerId: getters.userData.role === 'guide' || getters.userData.role === 'lecture' ? getters.userData?.id : null,
                isExit: false, /* true */
            }))
        },

        async fetchEventMembers({getters}, eventId) {
            getters.webSocket?.send(JSON.stringify({
                action: 'GET_EVENT_CLIENTS',
                token: getters.userToken,
                eventId
            }))
        },

        async joinEvent({getters, dispatch}, {eventId, /* isLobby = false */}) {
            // if (getters.userData.role === 'guide') {
            //     localStorage.setItem('game_socket', await this.dispatch('fetchInstance', 'main'));
            // }

            const eventData = sessionStorage.getItem('eventType') !== 'recordedTour' ? {
                action: 'EVENT_JOIN',
                token: getters.userToken,
                eventId,
                slotId: sessionStorage.getItem('slotId'),
                peerId: null,
                instance: null,
                // peerId: !isLobby && (getters.userData.role === 'guide' || getters.userData.role === 'lecture') ? getters.userData?.id :null,
                // instance: !isLobby && (getters.userData.role === 'guide') ? localStorage.getItem('game_socket') : null
            } : {
                action: 'EVENT_JOIN',
                token: getters.userToken,
                eventId,
                slotId: sessionStorage.getItem('slotId'),
                peerId: null,
                instance: null
            }

            await dispatch('fetchEventMembers', eventId);


            // if (getters.userData?.role !== 'guide' && getters.userData?.role !== 'lecture') {
            //     delete eventData.peerId;
            //     delete eventData.instance;
            // }


            // commit('setChatParams', {receiverId: eventId, chatType: 'event', receiverName: null});
            getters.webSocket?.send(JSON.stringify(eventData));
        },

        async fetchUserLocation({commit}) {
            const {data, status} = await fetchUserLocation();
            if (status === 200) {
                commit('setLocation', data?.location);
            }
        },

        async slotEventSubscribe({ getters, state }, { slotId, multiSub }) {
            console.table({
                slotId,
                clientId: getters.userData.id,
                multiSub
            })
            const res = await slotEventSubscribe(getters.userToken, {
                slotId,
                clientId: getters.userData.id,
                multiSub,
            });

            if (res.status !== 201) {
                errorHandler({name: 'subscribe-error', message: res.data.message});
                return false;
            }

            // if (res.data.message) {
            //     console.log('info')
            //     errorHandler({name: 'info', ...res.data});
            // }

            if (res.data.slots) {
                state.userData.slots = res.data.slots;
                // errorHandler({name: 'success'})
            }

            return true;
        },

        async slotEventUnsubscribe({getters}, data) {
            await slotEventUnsubscribe(getters.userToken, data);
        },


        connectionUserResponse({getters}, {callToUser, isAccepted}) {
            getters.webSocket.send(JSON.stringify({
                action: "CONNECTION_RESPONSE",
                callToUser,
                isAccepted,
                token: getters.userToken
            }))
        },

        initializeSocket({getters}) {
            getters.webSocket.send(JSON.stringify({action: "INITIALIZE", token: getters.userToken}));
        },


        //TODO TEST FAST AUTHORIZATION
        async sendCode({getters, commit}, code) {
            if (getters.token) return;
            const response = await sendCode(code);
            if (response.status === 200) {
                sessionStorage.removeItem('game_username');
                localStorage.setItem('token', response.data.token);
                commit('setToken', response.data.token);
            }
            errorHandler({status: response.status, name: 'authorization', meta: {data: response.data}})
            return response;
        },
        async fastAuthorization({getters}, email) {
            if (getters.token) return;
            let response = await fastAuthorization({email});
            errorHandler({status: response.status, name: 'fastAuthorization', meta: {data: response.data}});
        },


        // gets user auth token from cookies if needed
        async loadUserToken({getters, commit}) {
            if (getters.token && getters.userData) return;
            let token = localStorage.getItem('token');
            if (token) {
                commit('setToken', token);
                await this.dispatch('fetchUserData');
                return token;
            }
        },
        // default user authorization method. Saves token in cookies if success
        async submitAuthorization({getters, commit}, data) {
            if (getters.token) return;
            let response = await submitAuthorization(data);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                commit('setToken', response.data.token);
                await this.dispatch('fetchUserLocation');
            }
            // errorHandler({name: 'authorization', status: response.status, meta: {data: response.data}});
            return response
        },
        async authorizationThroughMos({getters, commit}, code) {
            if (getters.token) return;

            const response = await authorizationThroughMos(code);
            const {token} = response?.data ?? {};

            if (response.status === 200 && token) {
                localStorage.setItem('token', token);
                commit('setToken', token);
                console.log('token: ', token)
                await this.dispatch('fetchUserData');
                errorHandler({name: 'success'});
            } else {
                errorHandler({name: 'mos'});
            }
            const redirect = JSON.parse(localStorage.getItem('redirectRoute') ?? null) ?? {name: 'mainPage'};

            router.push(redirect)
        },
        async fetchUserData({getters, commit}) {
            const {data} = await fetchUserData(getters.userToken);
            commit('setUserData', data);
            if (getters.userLocation) {
                commit('setUserLocation');
                await this.dispatch('saveUserData', getters.userData);
                commit('setLocation', null);

            }
        },
        async saveUserData({getters}, data) {
            const {status} = await saveUserData(getters.userToken, data);
            errorHandler({name: 'save', status});
        },
        // default new user register method
        async submitRegistration({getters}, registrationData) {
            if (getters.token) return;
            return await submitRegistration(registrationData);
        },
        // default logout method. Clear auth token from Storage (cookies by default)
        logout({commit}) {
            localStorage.removeItem('token');
            commit('setToken', null);
            commit('setUserData', null);
            errorHandler({name: 'logout'});
        },
        async fetchInstance() {

            if (appConfig.isDesktopAppChecked && appConfig.isDesktopApp) {
                return appConfig.desktopSocket;
            }

            return null;

            // if (appConfig.domain === 'https://back.metamsk.ru') {
            //     return null;
            // }
            //
            // const {data, status} = await fetchInstance(getters.userToken, type);
            //
            // if (!data?.signallingServer && data?.error) {
            //     errorHandler({status, name: 'instance'});
            //     localStorage.setItem('game_status', 'no_instance');
            //     return null;
            // }
            //
            //
            // if (status === 200) {
            //     return `stream.metamsk.ru:${Number(data?.signallingServer?.split(':')?.[1]) + 1000}`;
            // }
            // if (status === 401) {
            //     errorHandler({status, name: 'instance'});
            //     localStorage.setItem('game_status', 'no_instance');
            //     return null;
            // }
        },
        async deleteInstance({getters, commit}, isAvatar) {
            if (isAvatar) {
                localStorage.removeItem('avatar_socket');
            } else {
                localStorage.removeItem('game_socket');
            }

            const test = true;
            if (test) return;
            if (getters.userData?.id === 'a909cdb4-5845-4a68-b764-15e6d07aeda2' || getters.userData?.id === 'c2645ad6-4510-488b-b13f-2893819f33cf') return;
            if (getters.isInstanceClear) return;
            const instanceIp = isAvatar ? localStorage.getItem('avatar_socket') : localStorage.getItem('game_socket');
            if (!instanceIp) return;
            await deleteInstance(getters.userToken, getters.unrealToken, {instanceIp});


            commit('setClearInstanceStatus', true);

        }
    },
    getters: {
        // get default user auth token
        userToken: (state) => state.token,
        userData: state => state.userData,
        webSocket: state => state.webSocket,
        unrealToken: state => state.unrealToken,
        userLocation: state => state.userLocation,
        isInstanceClear: state => state.isInstanceClear,
        disconnectTimer: state => state.disconnectTimer,
        showDisconnectModal: state => state.showDisconnectModal,
        eventMembers: state => state.eventMembers,
    }
};